import React from "react"
import Page from "../components/page"
import pageStyles from "../components/page.module.css"
import styles from "./cookies.module.css"

export default function PrivacyPolicy({ location }) {
  return (
    <Page title={"Privacy Policy – PhotoTailors"} location={location}>
      <div className={pageStyles.sectionWrapper}>
        <div className={pageStyles.section}>
          <div className={styles.main}>
            <div>

              <p>Photo Tailors Limited (“us”, “we”, or “our”) operates the https://www.phototailors.com website (the
                “Service”).</p>
              <p>This page informs you of our policies regarding the collection, use and disclosure of Personal
                Information when you use our Service.</p>
              <p>We use your Personal Information for providing and improving the Service. By using the Service, you
                agree to the collection and use of information in accordance with this policy. Unless otherwise defined
                in this Privacy Policy, terms used in this Privacy Policy have the same meanings as in our Terms and
                Conditions, accessible at https://www.phototailors.com</p>
              <p>&nbsp;</p>
              <h3>Information Collection and Use</h3>
              <p>While using our Service, we may ask you to provide us with certain personally identifiable information
                that can be used to contact or identify you. Personally identifiable information may include, but is not
                limited to, your email address, name, phone number, postal address, other information (“Personal
                Information”).</p>
              <p>&nbsp;</p>
              <h3>Log Data</h3>
              <p>We may also collect information that your browser sends whenever you visit our Service (“Log Data”).
                This Log Data may include information such as your computer’s Internet Protocol (“IP”) address, browser
                type, browser version, the pages of our Service that you visit, the time and date of your visit, the
                time spent on those pages and other statistics.</p>
              <p>&nbsp;</p>
              <p>In addition, we may use third party services such as Google Analyticsthat collect, monitor and analyze
                this type of information in order to increase our Service’s functionality. These third party service
                providers have their own privacy policies addressing how they use such information.</p>
              <p>&nbsp;</p>
              <h3>Cookies</h3>
              <p>Cookies are files with small amount of data, which may include ananonymous unique identifier. Cookies
                are sent to your browser from a web site and stored on your computer’s hard drive.</p>
              <p>We use “cookies” to collect information. You can instruct your browser to refuse all cookies or to
                indicate when a cookie is being sent. However, if you do not accept cookies, you may not be able to use
                some portions of our Service.</p>
              <h3>&nbsp;</h3>
              <h3>Behavioral Remarketing</h3>
              <p>Photo Tailors Limited uses remarketing services to advertise on third party web sites to you after you
                visited our Service. We, and our third party vendors, use cookies to inform, optimize and serve ads
                based on your past visits to our Service.</p>
              <p><strong>Google</strong></p>
              <p>Google AdWords remarketing service is provided by Google Inc.</p>
              <p>You can opt-out of Google Analytics for Display Advertising and customize the Google Display Network
                ads by visiting the&nbsp;<a href="http://www.google.com/settings/ads" target="_blank"
                                            rel="noopener noreferrer">Google Ads Settings page</a></p>
              <p>Google also recommends installing the&nbsp;<a href="https://tools.google.com/dlpage/gaoptout"
                                                               target="_blank" rel="noopener noreferrer">Google
                Analytics Opt-out Browser Add-on</a>&nbsp;for your web browser. Google Analytics Opt-out Browser Add-on
                provides visitors with the ability to prevent their data from being collected and used by Google
                Analytics.</p>
              <p>For more information on the privacy practices of Google, please visit the&nbsp;<a
                href="http://www.google.com/intl/en/policies/privacy/" target="_blank" rel="noopener noreferrer">Google
                Privacy &amp; Terms web page</a></p>
              <p>&nbsp;</p>
              <p><strong>Facebook</strong></p>
              <p>Facebook remarketing service is provided by Facebook Inc.</p>
              <p>You can learn more about interest-based advertising from Facebook by visiting&nbsp;<a
                href="https://www.facebook.com/help/164968693837950">this page</a></p>
              <p>To opt-out from Facebook’s interest-based ads follow&nbsp;<a
                href="https://www.facebook.com/about/ads/#568137493302217" target="_blank" rel="noopener noreferrer">these
                instructions from Facebook</a></p>
              <p>Facebook adheres to the Self-Regulatory Principles for Online Behavioral Advertising established by the
                Digital Advertising Alliance. You can also opt-out from Facebook and other participating companies
                through the&nbsp;<a href="http://www.aboutads.info/choices/" target="_blank" rel="noopener noreferrer">Digital
                  Advertising Alliance</a>&nbsp;in the USA, the&nbsp;<a href="http://www.youradchoices.ca/"
                                                                        target="_blank" rel="noopener noreferrer">Digital
                  Advertising Alliance of Canada</a>&nbsp;in Canada or the&nbsp;<a
                  href="http://www.youronlinechoices.eu/" target="_blank" rel="noopener noreferrer">European Interactive
                  Digital Advertising Alliance</a>&nbsp;in Europe , or opt-out using your mobile device settings.</p>
              <p>For more information on the privacy practices of Facebook, please visit&nbsp;<a
                href="https://www.facebook.com/privacy/explanation" target="_blank" rel="noopener noreferrer">Facebook’s
                Data Policy</a>.</p>
              <h2>&nbsp;</h2>
              <h3>Service Providers</h3>
              <p>We may employ third party companies and individuals to facilitate our Service, to provide the Service
                on our behalf, to perform Servicerelated services or to assist us in analyzing how our Service is
                used.</p>
              <p>These third parties have access to your Personal Information only to perform these tasks on our behalf
                and are obligated not to disclose or use it for any other purpose.</p>
              <p>&nbsp;</p>
              <h3>Communications</h3>
              <p>We may use your Personal Information to contact you with newsletters, marketing or promotional
                materials and other information that may be of interest to you. You may opt out of receiving any, or
                all, of these communications from us by following the unsubscribe link or instructions provided in any
                email we send or by contacting us.</p>
              <p>&nbsp;</p>
              <h3>Compliance With Laws</h3>
              <p>We will disclose your Personal Information where required to do so by law or in accordance with an
                order of a court of competent jurisdiction, or if we believe that such action is necessary to comply
                with the law and the reasonable requests of law enforcement or to protect the security or integrity of
                our Service.</p>
              <p>&nbsp;</p>
              <h3>Business Transaction</h3>
              <p>If Photo Tailors Limited is involved in a merger, acquisition or asset sale, your Personal Information
                may be transferred. We will provide notice before your Personal Information is transferred and becomes
                subject to a different Privacy Policy. In the event of such a transfer of information, your rights under
                the Data Protection Act 1998 are not affected.</p>
              <p>&nbsp;</p>
              <h3>Security</h3>
              <p>The security of your Personal Information is iportant to us, but remember that no method of
                transmission over the Internet, or of electronic storage is 100% secure. While we strive to use
                commercially acceptable means to protect your Personal Information, we cannot guarantee its absolute
                security. As such we make no warranties as to the level of security afforded to your data, except that
                we will always act in accordance with the relevant UK and EU legislation.</p>
              <p>&nbsp;</p>
              <h3>International Transfer</h3>
              <p>Your information, including Personal Information, may be transferred to — and maintained on — computers
                located outside of your state, province, country or other governmental jurisdiction where the data
                protection laws may differ than those from your jurisdiction.</p>
              <p>If you are located outside United Kingdom and choose to provide information to us, please note that we
                transfer the information, including Personal Information, to United Kingdom and process it there</p>
              <p>Your consent to this Privacy Policy followed by your submission of such information represents your
                agreement to that transfer.</p>
              <p>In the event that a dispute arises with regards to the international transfer of data, you agree that
                the courts of England and Wales shall have exclusive jurisdiction over the matter.</p>
              <h2>&nbsp;</h2>
              <h3>Links To Other Sites</h3>
              <p>Our Service may contain links to other sites that are not operated by us. If you click on a third party
                link, you will be directed to that third party’s site. We strongly advise you to review the Privacy
                Policy of every site you visit.</p>
              <p>We have no control over, and assume no responsibility for the content, privacy policies or practices of
                any third party sites or services.</p>
              <h2>&nbsp;</h2>
              <h3>Children’s Privacy</h3>
              <p>Our Service does not address anyone under the age of 13 (“Children”)</p>
              <p>We do not knowingly collect personally identifiable information from children under 13. If you are a
                parent or guardian and you are aware that your Children has provided us with Personal Information,
                please contact us. If we become aware that we have collected Personal Information from a children under
                age 13 without verification of parental consent, we take steps to remove that information from our
                servers.</p>
              <h2>&nbsp;</h2>
              <h3>Changes To This Privacy Policy</h3>
              <p>We may update our Privacy Policy from time to time. We will notify you of any changes by posting the
                new Privacy Policy on this page.</p>
              <p>You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy
                Policy are effective when they are posted on this page</p>
              <p>If we make any material changes to this Privacy Policy, we will notify you either through the email
                address you have provided us, or by placing a prominent notice on our website.</p>
              <p>&nbsp;</p>
              <h3>Jurisdiction</h3>
              <p>This Policy shall be governed and construed in accordance with the laws of England and Wales, without
                regard to its conflict of law provisions.</p>
              <h2>&nbsp;</h2>
              <h3>Contact Us</h3>
              <p>If you have any questions about this Privacy Policy, please&nbsp;<a
                href="/contact-us/">contact us.</a></p>
            </div>
          </div>
        </div>
      </div>
    </Page>


  )
}
